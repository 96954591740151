'use client';
import { useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';

export const Dropdown = ({ items, onUpdate }: any) => {
	const detailsElement = useRef<HTMLDetailsElement>(null),
		[selected, setSelected] = useState(items?.[0]),
		[displayBackdrop, setDisplayBackdrop] = useState<'initial' | 'none'>('none');

	function toggleDropdown(to: boolean) {
		if (detailsElement.current) {
			detailsElement.current.open = to;
		}
	}

	function onToggle() {
		const newDisplay = detailsElement.current?.open ? 'initial' : 'none';
		setTimeout(
			() => {
				setDisplayBackdrop(newDisplay);
			},
			newDisplay === 'initial' ? 0 : 200,
		);
	}
	function onKeyDown(e: any) {
		if (e.key === 'Escape') {
			toggleDropdown(false);
		}
	}
	function onBlur(e: any) {
		const { relatedTarget } = e;
		if (!relatedTarget || relatedTarget.dataset?.dropdown === undefined) {
			toggleDropdown(false);
		}
	}
	function selectItem(e: any) {
		if (e.code && !['Enter', 'Space'].includes(e.code)) {
			return;
		}
		const value = e.target.innerHTML;
		setSelected(value);
		onUpdate(value);
		toggleDropdown(false);
	}

	useEffect(() => {
		setSelected(items?.[0]);
	}, [items]);

	return (
		<details
			ref={detailsElement}
			className={styles.toggle}
			onToggle={onToggle}
			onBlur={onBlur}
			onKeyDown={onKeyDown}
			style={{ '--display-backdrop': displayBackdrop } as React.CSSProperties}
		>
			<summary className={styles.selected} data-dropdown>
				<span>Sort by: {selected}</span>
				<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M6 9L12 15L18 9'
						stroke='currentColor'
						strokeWidth='2'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</svg>
			</summary>
			<div className={styles.content}>
				<div className={styles.list}>
					{items.map((i: any, index: number) => (
						<button
							key={index}
							type='button'
							className={styles.item}
							data-dropdown
							onMouseDown={selectItem}
							onTouchStart={selectItem}
							onKeyDown={selectItem}
						>
							{i}
						</button>
					))}
				</div>
			</div>
		</details>
	);
};
